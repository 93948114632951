body {
    background-color: black !important;
}

.num-btn-on, .num-btn-off {
    /* width: var(--num-btn-size);
    height: var(--num-btn-size); */
    transform: translateX(0px) translateY(0px);
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, .3);

}



.colour-btn {
    top: 4%;
    cursor: pointer;
}

.colour-btn:hover {

    height: 35px;
    width: 35px;
}


.colour-btn.black-btn {
    background-color: black;
    left: 45%;

}


.colour-btn.white-btn {
    background-color: white;
    left: 53%;

}

.colour-btn.red-btn {
    background-color: rgb(216, 8, 8);
    left: 60%;

}

.colour-btn.green-btn {
    background-color: green;
    left: 67%;

}

.colour-btn.blue-btn {
    background-color: blue;
    left: 75%;

}

.colour-btn.purple-btn {
    background-color: purple;
    left: 84%;

}

.colour-btn.yellow-btn {
    background-color: yellow;
    left: 94%;
    top: 10%;
}

.colour-btn.orange-btn {
    background-color: orange;
    left: 95.1%;
    top: 50%;
}


.colour-btn.pink-btn {
  background-color: #FFABD5;
  left: 95.1%;
  top: 90%;
}

.expired-notice {
    text-align: center;
    padding: 2rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.5rem;
  }
  
  .expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
  }
  
  .expired-notice > p {
    font-size: 1.5rem;
  }
  
  .show-counter {
    padding: 0.5rem;
  }
  
  .show-counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0.5rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #000;
  }
  
  .show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .show-counter .countdown.danger {
    color: #ff0000;
  }
  
  .show-counter .countdown > p {
    margin: 0;
  }
  
  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
  }
